.card {
  &__container {
    //

    display: flex;
    max-width: 130rem;
    justify-content: space-between;
    gap: 2.4rem;
    & > * {
      width: 100%;
    }
  }

  &-component {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    gap: 1.6rem;
    align-items: center;
    max-width: 25rem;
    min-height: 20rem;
    padding: 2.5rem 1.8rem;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 2px solid hsla(0, 0%, 31%, 0.2);

    // updated chart

    .card__image-container {
      width: 20rem;
      flex: 1;
      // max-height: 10rem;

      .chart__header {
        display: none;
      }

      .App {
        height: 100%;
      }

      svg {
        width: 100%;
        height: 100%;
        display: block;
      }

      .chart__img {
        height: 100% !important;
      }
    }

    .img__description {
      font-family: poppins;
      font-weight: 500;
      font-size: 1.6rem;
      color: #5e5e5e;
    }
  }
}

.animate {
  &:hover {
    // background: $color-primary;
  }
  transition: all 0.3s;

  & svg {
    transition: all 0.3s;
  }
  & svg path {
    transition: all 0.4s;
  }

  &:hover svg path,
  &:active svg path,
  &:focus svg path {
    fill: $color-light-white;
  }

  &:hover svg,
  &:active svg,
  &:focus svg {
    transform: scale(1.2);
  }

  &:hover .white__stroke {
    stroke: $color-light-white;
    fill: $color-primary;
  }

  .img__description {
    transition: all 0.3s;
  }

  &:hover .img__description,
  &:active .img__description,
  &:focus .img__description {
    // color: $color-light-white;
    transform: scale(1.2);
  }
}

// responsive
@media (max-width: 1130px) {
  .first__section-card {
    .card {
      &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: 900px) {
          grid-template-columns: repeat(2, 1fr);

          @media (max-width: 585px) {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }

      &-component {
        width: 100%;
        max-width: none;

        & .card__image-container {
          width: auto;
        }
      }
    }
  }
}
