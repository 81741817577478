.table__size {
  @media (max-width: 768px) {
    width: 70rem;
    overflow: scroll;
  }

  .modal__table {
    // width: 80rem;
    margin: auto;
    // overflow-x: auto;

    th {
      background: $color-primary;
    }

    table,
    th,
    td {
      border: 1px solid $color-light-white;
      border-collapse: collapse;
      color: $color-light-white;
    }

    th,
    td {
      padding: 15px;
    }

    td {
      color: $color-black-shade;
    }

    tr {
      border-bottom: 0px solid transparent !important;
    }

    tr:nth-child(even) {
      background-color: #eae7d9;
    }

    tr:nth-child(odd) {
      background-color: #f9f8f4;
    }

    tr td:nth-child(1) {
      background-color: $color-primary;
      color: $color-light-white;
    }
  }
}
