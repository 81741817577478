.btn {
  &,
  &:link,
  &:visited {
    font-size: $default-font;
    font-family: $secondary-font;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    text-align: center;
    display: inline-block;
    border-radius: 1.5rem;
    transition: all 0.2s ease;
    position: relative;

    border: none; //for button
    cursor: pointer;
  }

  &--white {
    background-color: $color-light-white;
    color: $color-dark;
  }

  &--orange {
    background-color: $color-btn-primary;
    color: $color-light-white;
    align-self: flex-start;

    &::after {
      background-color: $color-btn-primary;
    }
  }

  &--transparent {
    width: 50%;
    background-color: $color-primary;
    border: 2px solid $color-light-white;
    color: $color-light-white;

    // &::after {
    //   background-color: $color-primary;
    // }
  }

  &--save {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    border-radius: 5px;

    svg {
      width: 20px;
    }
  }

  &--blue {
    background-color: $color-primary;
    color: $color-light-white;
  }

  &:hover {
    transform: translateY(-0.3rem);
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }
  &:active,
  &:focus {
    outline: none;
    transform: translateY(-0.1rem);
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    display: inline-block;
    border-radius: 4rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }

  &--animate {
    animation: moveButton 0.5s ease-out 0.75s;
    animation-fill-mode: backwards;
  }

  &-logout {
    background: transparent;
    color: $color-primary;
    border-radius: 10px;
    // padding: 1.2rem 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
    font-size: 1.4rem;

    // fixes
    position: absolute;
    right: 44px;

    &:hover {
      transform: translateY(0) scale(1.2);
      // box-shadow: 0 1rem 2rem rgba($color-black, 0.2);
      box-shadow: none;
      &::after {
        transform: scaleY(0);
        opacity: 0;
      }
    }
  }
}
