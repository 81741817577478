.dynamic {
  &-table__container {
    display: flex;
    flex-direction: column;
    gap: 2.6rem;
    padding: 1.6rem 2.4rem;

    button {
      align-self: flex-end;
    }
  }

  &__table {
    // max-width: 120rem;
    overflow: auto;
    &::-webkit-scrollbar {
      // display: none;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  overflow: scroll;
}

.table td,
.table th {
  // border-top: 1px solid #ecf0f1;
  padding: 10px;
  width: 25rem;
}
.table td {
  // border-left: 1px solid #ecf0f1;
  // border-right: 1px solid #ecf0f1;
  //   max-width: 40rem;
}
.table th {
  background-color: $color-primary;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: capitalize;
}

.box__container {
  position: relative;
  width: 100%;
}

.table .color__picker {
  width: 30%;
  position: absolute;
  right: -15%;
  top: 0%;
  height: 100%;
  border-radius: 15px;
  -webkit-appearance: none;
  -mox-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.colorpick-eyedropper-input-trigger {
  display: none !important;
}

.table input {
  width: 100%;
  padding-right: 15%;
  outline: none;
}

.table tr:nth-of-type(even) td {
  background-color: #d9f4f2;
}
.table .total th {
  background-color: white;
}
.table .total td {
  text-align: right;
  font-weight: 700;
}

// #input-0-0 {
//   background-color: red;
// }

.input__box {
  position: relative;
}

.effect-8 {
  border: 1px solid #ccc;
  padding: 7px 14px 9px;
  transition: 0.4s;
}

.effect-8 ~ .focus-border:before,
.effect-8 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: $color-primary;
  transition: 0.3s;
}

.effect-8 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}

.effect-8 ~ .focus-border i:before,
.effect-8 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: $color-primary;
  transition: 0.4s;
}

.effect-8.table__heading ~ .focus-border i:before,
.effect-8.table__heading ~ .focus-border i:after,
.effect-8.table__heading ~ .focus-border:before,
.effect-8.table__heading ~ .focus-border:after {
  background-color: $color-table-heading;
}

.effect-8 ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.effect-8:focus ~ .focus-border:before,
.effect-8:focus ~ .focus-border:after {
  width: 100%;
  transition: 0.3s;
}
.effect-8:focus ~ .focus-border i:before,
.effect-8:focus ~ .focus-border i:after {
  height: 100%;
  transition: 0.4s;
}

//  responsive
@media (max-width: 1370px) {
  .tabs__style {
    .list {
      &__container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-items: center;

        @media (max-width: 1206px) {
          grid-template-columns: repeat(5, 1fr);
        }

        @media (max-width: 1026px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width: 826px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 600px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  @media (max-width: 1055px) {
    .tabs__list {
      flex-wrap: wrap;

      .tabs__item {
        white-space: nowrap;
      }

      @media (max-width: 650px) {
        column-gap: 2.4rem;
        @media (max-width: 400px) {
          // font-size: 1.4rem;
        }

        .tabs__item {
          text-align: start;
        }

        .tabs__item:nth-child(3) {
          width: max-content;
        }

        .tabs__item:nth-child(4) {
          @media (max-width: 400px) {
            order: 5;

            width: max-content;
          }
        }
        .tabs__item:nth-child(5) {
          width: min-content;

          @media (max-width: 490px) {
            width: 33%;
          }
        }
      }
    }
  }
}
