@mixin mobile-L {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin laptop-S {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin laptop-M {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin laptop-L {
  @media (min-width: 1920px) {
    @content;
  }
}
*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;

  @include laptop-M {
    font-size: 68%;
  }
  @include laptop-L {
    font-size: 75%;
  }
}

body {
  font-size: 1.6rem;
  font-family: poppins, sans-serif;
  overflow-x: hidden;
  min-height: 70vh;
}

.constant__width {
  max-width: 150rem;
  margin: 0 auto;
}

.constant__padding {
  padding: 1.6rem 2.4rem;

  @include tablet {
    padding: 2.4rem 4rem;
  }
}

.min_height {
  min-height: 100vh;
}

.constant__margin-top {
  margin-top: 3rem;
}
.constant__margin-bottom {
  margin-bottom: 3rem;
}

.title__color {
  color: $color-primary;
}

.section__heading {
  font-family: poppins;
  color: $color-primary;
  font-weight: 600;
  font-size: 2rem;
}

// @media (min-width: 768px) {
//   html {
//     font-size: 80%;
//   }
// }

#root {
  position: relative;
  height: 100%;
}

.red__border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  background: $color-primary;
}
