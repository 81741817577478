:root {
  --max-width: 5rem;
}

.accordian {
  &__section {
    margin-top: 6rem;

    h2 {
      text-align: center;
    }
  }

  &__block {
    padding: 0.6rem 1.6rem;
    font-size: 1.6rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // max-height: var(--max-width);

    .title {
      font-weight: 500;
      text-align: center;
      //
      //   min-height: 8rem;
      color: $color-dark;
      display: flex;
      align-items: center;

      &.updated__title {
        cursor: pointer;
        transition: all 0.3s;
        flex: 1;
        // text-decoration: underline;
      }

      p {
        color: $color-primary;
        font-weight: bold;
        font-family: poppins;
        margin: 0;
      }
    }

    .content {
      font-size: 1.8rem;
      height: 0;
      overflow: hidden;
      transition: all 0.3s;
      //   transform: scaleY(0);
    }
  }

  &__custom {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    // margin-top: 2.4rem;
    // max-width: 70rem;
  }
}

.title {
  position: relative;

  p {
    width: 90%;
  }
}

.transform-rotate-icon {
  font-size: 2.8rem;
  color: $color-primary;
  transition: all 0.3s ease;
}

// accordian components
.common__component {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem !important;
  padding: 0.8rem 0;

  &:not(:last-child) {
    border-bottom: 2px solid #cccc;
    margin-bottom: 0.8rem;
  }

  .counter {
    width: 10%;
    margin: 0;
  }

  .title {
    color: #4e4e4e;
    text-transform: uppercase;
    flex: 1;
  }
}

.accordian__button {
  outline: none;
  background: transparent;
  padding: 0.4rem 1.2rem;
  color: $color-primary;
  font-size: 1.2rem;
  border-radius: 10px;
  border: 2px solid $color-primary;
  transition: all 0.3s;

  &:hover,
  &:active {
    background: $color-primary;
    color: $color-light-white;
    cursor: pointer;
  }
}

table {
  // border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;

  .sn {
    width: 3rem;
  }

  tbody tr:not(:last-child) {
    border-bottom: 2px solid #cccc;
    margin-bottom: 0.8rem;
  }

  .accordian__button {
    font-size: 1.2rem;
  }
}

table tr {
  padding: 0.35em;

  .title {
    display: block !important;
    // margin-left: 1.8rem;
  }
}

table th {
  font-size: 0.85em;
  font-family: poppins;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

table th,
table td {
  font-size: 1.2rem;
  padding: 0.625em;
  text-align: center;
}

.common__component .date {
  background-color: $color-primary;
  margin-right: 2rem;
  width: 3.4rem;
}

.calender {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-family: poppins;
  font-size: 1.3rem;
  line-height: 1.4;
}

.common__component {
  .updated__title:hover {
    text-decoration: underline;
    color: $color-primary;
  }
}
