.tabs__style {
  //   max-width: 600px;
}

.tabs {
  &__list {
    padding-left: 0;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    // changes
    flex-wrap: nowrap !important;

    overflow-x: scroll;
    column-gap: 2.4rem;
  }
  &__item {
    position: relative;
    display: inline-block;
    text-transform: Capitalize;
    padding: 15px 0;
    width: calc(100% / 3);
    text-align: center;
    white-space: nowrap;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      width: 0;
      background: #cd0303;
      transition: 0.3s;
    }
    &.active:after {
      width: 100%;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__content-wrapper {
    position: relative;
  }
  &__content {
    padding-top: 2.4rem;
    max-width: 160rem;
    margin: 0 auto;
    display: none;
    &.active {
      display: block;
    }
  }
}
