.slider {
  &ImageContainer {
    // width: 100%;
    height: 70vh;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: cover;
    }

    &::after {
      content: "";
      background: hsla(0, 0%, 0%, 0.402);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.sahayatri__slider {
  width: 75%;

  &-right {
    right: 27px !important;

    svg {
      background: transparent !important;
      color: #565656 !important;
      color: $color-light-white !important;
      transition: all 0.2s;

      &:hover {
        transform: scale(2);
      }
    }
  }

  &-left {
    left: 27px !important;
    z-index: 5;

    svg {
      background: transparent !important;
      // color: #565656 !important;
      color: $color-light-white !important;
      transition: all 0.2s;

      &:hover {
        transform: scale(2);
      }
    }
  }
  @media (max-width: 1150px) {
    width: 100%;
  }
}

.multiple__slider {
  .card-component {
    // max-width: 20rem;
    // max-height: none !important;
    // max-height: 20rem !important;
    max-width: 26rem;
    max-height: 22rem !important;
    margin: 0 auto;

    @media (max-width: 1000px) {
      // margin: 0 1rem;
    }

    .card__image-container {
      height: 10rem;
      width: 100%;
    }

    &.multi-slider-card {
      max-width: none;
      max-height: none !important;

      margin: 1rem;
    }
  }
}

.slick-next:before,
.slick-prev:before {
  display: none;
}

.slick {
  &-arrow {
  }

  &-next svg,
  &-prev svg {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background: $color-primary;
    color: #fff;
  }
}

@media (max-width: 800px) {
  .sahayatri__slider-right {
    right: 27px !important;
  }
  .sahayatri__slider-left {
    left: 27px !important;
    z-index: 5 !important;
  }
}

.multiple__slider {
  .slick-slide .slick-active slick-current {
  }
}

.content-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  background-color: hsla(4, 77%, 48%, 0.493);
  color: $color-light-white;
  padding-left: 1.6rem;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
