@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

$color-light-white: #fff;
$color-primary: #da281c;
$color-section-background-1: #fff3e4;
$color-blue: #2f327d;
$color-dark: #000;
$color-black: #000;
$color-black-shade: #464646;
$color-btn-primary: #ff9201;
$default-font: 1.6rem;
$color-green: #8ad7bc;
$color-purplish-blue: #696984;
$color-grey: #8ad7bc;
$color-footer-text: #b2b3cf;
$color-gray-dark: #4e4e4e;
$color-black-shade: #2f2f2f;
$color-table-heading: #b8b8b8;
// font family
$global-font: poppins;
$secondary-font: Roboto;
$default-font: 1.6rem;
