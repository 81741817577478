.homepage {
  max-width: 150rem;
  margin: 0 auto;
  //
  position: relative;
}

// first section
.first__section {
  display: flex;
  align-items: flex-start;
  gap: 1.6rem;

  .accordian-section {
    flex: 1;
  }

  // media
  @media (max-width: 1150px) {
    flex-direction: column;
  }
}

// fourth section
.fourth__section {
}

.table {
  &__container {
    display: flex;
    padding-top: 1.6rem;
    gap: 1.6rem;
    & > * {
      width: 100%;
    }

    span {
      text-align: center;
      font-weight: 500;
      font-size: 1.8rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }
}

.view__more {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  justify-content: flex-end;
  margin-top: -0.8rem;
  padding-right: 1.6rem;
  align-self: flex-end;

  span {
    color: $color-primary;
    font-size: 1.6rem;
    transition: all 0.3s;
  }
  span:first-child {
    text-decoration: underline;
  }

  &:hover span:first-child {
    transform: translateX(-0.3rem);
  }
  &:hover span:last-child {
    transform: translateX(0.3rem);
  }
}

// fifth section
.fifth {
  justify-content: space-between;
  &__section {
  }

  .table__content,
  .list__container {
    max-width: 35rem;
  }
}

// second Section

.chart {
  &__display {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;

    & > * {
      width: 100%;
    }
  }

  &__container-1,
  &__container-2,
  &__container-3 {
    max-width: 30%;
  }

  &__container-2 {
  }

  &__img {
    width: 100% !important;
  }
  // padding: ;
}
.vis-card-title {
  font-weight: bold;
  margin-left: 2rem;
  margin-top: 1rem;

  display: inline-block;

  &:hover {
    cursor: pointer;
    color: $color-primary;
  }
}

.preview-about {
  strong {
    color: $color-primary;
  }
}
