.charts__page {
  .first__section {
    .big__chart {
      width: 75%;
      min-height: 100vh;

      @media (max-width: 1150px) {
        width: 100%;
        min-height: 0;
        margin-bottom: 2rem;
      }

      .chart__img {
        height: 100% !important;
      }
    }

    .multi__column-chart {
      @media (max-width: 1150px) {
        display: flex;
        gap: 1.6rem;
        width: 100%;

        & > * {
          width: 100%;
        }

        @media (max-width: 630px) {
          flex-direction: column;
        }
      }
      .chart__container {
        max-width: none;
        width: 100%;
      }
    }
  }

  .whole__page-chart {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 3.4rem;

    .chart__container {
      max-width: none;
      width: 100%;
    }

    @media (max-width: 1256px) {
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: 630px) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 1.6rem;
      }
    }
  }
}

// homepage charts
.multiple__slider-chart {
  // & .slick-track {
  //   max-width: 150rem !important;
  //   width: auto !important;
  // }

  & .slick-slide {
    // max-width: 20rem;

    canvas {
      margin: 0 auto;
      width: 90% !important;
    }
  }
}
