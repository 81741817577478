@import "./base/variable.scss";

// @import "./icon.scss";

@import "./base/base.scss";

@import "./base/typography.scss";

@import "./components/Accordian.scss";

@import "./layout/header.scss";

@import "./layout/footer.scss";

@import "./components/button.scss";

@import "./components/form.scss";

@import "./Pages/Homepage/Homepage.scss";

@import "./components/Card.scss";

@import "./components/List.scss";

@import "./abstract/table.scss";

@import "./components/Tab.scss";

@import "./components/Search.scss";

@import "./layout/InputSelect.scss";

@import "./abstract/responsive.scss";

@import "./layout/chartPage.scss";

@import "./layout/modalTable.scss";

@import "./abstract/404pageNotFound.scss";

@import "./abstract/slider.scss";
// #root {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
// }
// footer {
//   margin-top: auto;
// }
.heading-h {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  color: $color-primary;
  font-weight: bold;
}

// .err__form {
//   color: #fff;
//   position: fixed !important;
//   height: 35rem;
//   right: 0;
//   bottom: 0;
//   background-color: #c8102e;
//   z-index: 5000000000;
//   padding: 5px 0 10px 0;
//   margin: 0 5px 5px 0;
//   width: 300px;
//   position: relative;
//   overflow: hidden;
//   transition: max-height 2s linear;
// }
// modal
.modal-sahayatra {
  position: fixed;
  background: $color-light-white;
  padding: 2rem 2.4rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  justify-content: center;
  align-items: center;
  &__success {
    width: 45%;
    margin: 0 auto;

    svg {
      width: 100%;
    }

    &__text-heading {
      font-size: 2rem;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    text-align: center;

    &-heading {
      color: $color-black-shade;
      font-size: 2rem;
    }

    &-description {
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
    }
  }
}
.btn-modal {
  padding: 0.5rem 2rem;
  align-self: center;
  border-radius: 20px;
  border: 2px solid $color-primary;
  background: $color-light-white;
  cursor: pointer;
  outline: none;
  color: $color-primary;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.3s;
  &:hover,
  &:active,
  &:focus {
    transform: scale(1.1);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
.btn-modal-sm {
  padding: 0.25rem 1rem;
  font-size: 1rem;
  align-self: center;
  border-radius: 20px;
  border: 2px solid $color-primary;
  background: $color-light-white;
  cursor: pointer;
  outline: none;
  color: $color-primary;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  &:hover,
  &:active,
  &:focus {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
.overlay-2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  transform: scale(0);
  z-index: 100;
  transition: all 0.1s;
}

.overlay_active {
  transform: scale(1);
}

.show {
  z-index: 500;
  transition: all 0.3s;
  transform: translate(-50%, -50%) scale(1);
}

.links__page {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  a {
    display: flex;
    gap: 1.6rem;
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader-sm {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.links__page-sahayatra {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  row-gap: 1.6rem;

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.seperate__head {
  display: flex;
  flex-direction: column;
  font-size: 2.5rem;

  span {
    font-size: 2rem;
  }
}

.blog-page {
  max-width: 70rem;
  margin: 0 auto;
}

.actions {
  margin-top: 2rem;
}

.view__mode {
  background: transparent;
  margin-right: 1.6rem;
  box-shadow: none;
  transition: all 0.2s;
  border-radius: 5px;
  border: 2px solid $color-primary;
  &:hover {
    transform: scale(1.2);
    box-shadow: none;
  }
}

.remove-box {
  box-shadow: none !important;
  border-radius: 5px;
  background: $color-primary;
  border: none;
  color: $color-light-white;
  padding: 0.6rem 1.4rem;
}

.about-content {
  width: 75%;

  @media (max-width: 1150px) {
    width: 100%;
  }

  .preview-about {
    & > :first-child {
      display: none;
    }
  }

  .constant__padding {
    padding: 0;
    padding-top: 2.4rem;
    min-height: auto;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.chart-box {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;

  .btn-modal-sm {
    align-self: flex-start;
  }

  .vis-card-title {
    margin-left: 0;
  }
}

#root {
  display: flex;
  flex-direction: column;

  .aligned {
    flex: 1;
  }
}

.svg_container{
  svg{
    font-size: 1rem;
  }
}