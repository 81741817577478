.form__content-sign {
  display: flex;
  flex-direction: column;
  // min-height: 30%;
  min-height: 50%;
  justify-content: space-around;
  align-items: center;
  gap: 3.4rem;
  background: $color-primary;
  padding-top: 5rem;
  padding-bottom: 5rem;
  height: auto !important;

  h2 {
    color: $color-light-white;
    border-bottom: 5px solid $color-light-white;
  }
}

.form {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &--control {
    margin-bottom: 2.5rem;
  }

  &-actions {
    text-align: center;
  }

  p {
    color: #fff;
    font-weight: 500;
  }
}

.form__container {
  display: grid;
  max-width: 95rem;
  margin: 0 auto;
  margin-top: 10rem;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: 1fr;

  .image {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      grid-gap: 3.4rem;
      gap: 3.4rem;
      padding-top: 5rem;
      height: 70%;
      // padding-bottom: 5rem;
      overflow: hidden;
      .welcome__text {
        padding-top: 5rem;
      }
    }

    img {
      width: 100%;
      max-height: 80%;
      display: block;
      object-fit: cover;
    }
  }
}

.form__group {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  svg {
    width: 2rem;

    path {
      //   fill: #f8f8f8;
    }
  }
}

.form--control {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  label,
  .label__text {
    color: $color-light-white;
    width: 48%;
  }

  input,
  .input {
    width: 100%;
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 1rem 1.6rem;
    border-radius: 2px;
    border: none;
    transition: all 0.3s;
    outline: none;
    background: #f9f9f9 !important;
    font-family: poppins !important;

    &:focus {
      box-shadow: 0 1rem 2rem rgb(0 0 0 / 10%);
    }
  }
}

.ui.selection.dropdown .menu > .options {
  font-family: poppins !important;
  padding: 1rem 1.6rem !important;
  font-size: 1.4rem;
}

// registration form
.register {
  &__container {
    max-width: 130rem;

    .image__content,
    .form__content-sign {
      height: 90%;
    }

    .form-actions {
      margin-top: 2rem;
    }
  }

  &__form {
    @media (min-width: 600px) {
      .form__group,
      .label__text {
        label {
          flex: 50%;
          display: flex !important;
          justify-content: space-between;
        }
      }
    }
  }
}

// responsive

@media (max-width: 1057px) {
  .register__container {
    .form__group {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media (max-width: 768px) {
  .register__container {
    .form__group {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .form__container {
    grid-template-columns: 1fr;
    max-width: 50rem;
    margin-top: 10rem;
    // position: relative;
    // top: 50%;
    // transform: translateY(-50%);

    .image__content {
      display: none;
    }

    .form__content-sign {
      height: 100%;
    }

    &.register__container {
      max-width: 70rem;
      // transform: translateY(-40%);
    }
  }

  @media (max-width: 650px) {
    .register__container {
      max-width: 55rem;
      .register__form {
        width: 90%;
      }
      .form__group {
        flex-direction: column;
        align-items: flex-start;

        label span:last-child {
          display: none;
        }
      }
    }
  }
}

.links__cr {
  display: flex;
  flex-direction: column;
  padding-top: 0.8rem;
  justify-content: flex-start;
  width: 80%;
  gap: 1.6rem;
  transition: all 0.3s;

  p {
    color: $color-light-white;
    font-weight: bold;
  }

  a {
    width: max-content;
    position: relative;
    transition: all 0.3s;
    font-weight: 400;

    &::after {
      content: "";
      position: absolute;
      height: 2px;
      background-color: $color-light-white;
      width: 20px;
      bottom: -5px;
      left: 0;
      transition: all 0.3s;
    }
    &:hover,
    &:active {
      color: hsl(0, 0%, 95%);
      text-decoration: none;

      &::after {
        width: 100%;
      }
    }
  }
}

// recent fixes
.register__container {
  .form__content-sign {
    margin-top: 5rem;

    @media (max-width: 651px) {
      // margin-top: 10rem;
    }
  }

  @media (max-width: 651px) {
    // position: static !important;
    // transform: translateY(0) !important;
  }
}

.dot__navigation {
  cursor: pointer;
}
