footer {
  background: $color-primary;
  display: flex;
  //   gap: 4rem;
  justify-content: space-between;
  color: $color-light-white;

  .footer {
    &__title {
      font-size: 1.7rem;
    }

    &__items {
      display: flex;
      flex-direction: column;

      a {
        color: $color-light-white;
      }

      &-1 {
        width: min-content;
        gap: 0.8rem;

        a {
          font-size: 1.7rem;
        }
      }
      &-2 {
        width: max-content;
        gap: 0.8rem;
      }
      &-3 {
        width: max-content;
        gap: 0.8rem;
      }
      &-4 {
        // flex: 1;
        width: 35rem;
        // align-self: flex-end;

        .social__links {
          display: flex;
          gap: 1.8rem;
          justify-content: center;
          .text {
          }

          a {
            font-size: 2rem;
          }
        }
      }
      &-link {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        a {
          font-size: 1.4rem;
        }
      }
    }

    &__logo {
      width: 35rem;

      img {
        width: 100%;
        display: block;
      }
    }
  }
}

// responsive
@media (max-width: 1300px) {
  footer {
    display: grid;
    grid-template-columns: minmax(5rem, 15rem) repeat(3, 1fr);
    row-gap: 2.4rem;

    @media (max-width: 1000px) {
      .footer__items-3 {
        grid-row: 2;
        grid-column: 1 / 3;
        width: 100%;
      }

      .footer__items-4 {
        grid-row: 2;
        grid-column: 3 / -1;
        justify-self: end;
      }

      .footer__items-5 {
        grid-row: 1;
        grid-column: 3 / -1;
      }

      @media (max-width: 910px) {
        .footer__items-5 {
          grid-row: 1;
          grid-column: 3 / -1;
          padding-left: 4rem;
        }

        @media (max-width: 768px) {
          .footer__items-5 {
            padding-left: 0;
          }

          .footer__items-4 {
            grid-row: 3;
          }

          .footer__items-2 {
            grid-row: 2;
            grid-column: 1/ 3;
          }

          .footer__items-2 {
            grid-column: 3 / -1;
          }

          @media (max-width: 650px) {
            .footer__items-4 {
              grid-row: 1;
            }

            .footer__items-5 {
              grid-row: 3;
              grid-column: 1 / -1;
            }

            @media (max-width: 580px) {
              .footer__items-2 {
                grid-column: 4 / -1;
              }

              .footer__items-3 {
                grid-column: 1 / 4;
              }

              .footer__items-4 {
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  footer {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .footer__items-1,
  .footer__items-2,
  .footer__items-3,
  .footer__items-4,
  .footer__items-5 {
    grid-row: auto !important;
    grid-column: auto !important;
  }
}
