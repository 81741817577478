// constant
header {
  // background: $color-section-background-1;
}

.padding {
  padding: 0.8rem 1.4rem;
}

/* utility style */
.nav-area.animate-nav {
  position: relative;
  top: -71px;
  transition: all 0.3s;
}

.nav-area.sticky-nav {
  position: fixed;
  top: 0;
  z-index: 100;
  transition: all 0.3s;
  /* padding: 0.8rem 1.6rem; */
  /* opacity: 0.8; */
}

.section {
  padding: 1.6rem 5.4rem;
}

a {
  text-decoration: none;
  font-family: inherit;
}

ul {
  list-style: none;
}

nav {
  width: 100%;
  //   box-shadow: 0 2px 8px -4px rgba(0, 0, 0, 0.8);
  // background-color: #fff;
}

// logo
.logo {
  width: 25rem;

  a {
    display: block;

    svg,
    img {
      width: 100%;
    }
  }
}

/* hamburger */
.hamburger-menu-container {
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  display: none;
}

.hamburger-menu {
  width: 4rem;
  height: 4rem;
  background-color: $color-primary;
  display: flex;

  justify-content: center;
  align-items: center;

  div {
    width: 2.5rem;
    height: 3px;
    background-color: var(--color-light-white);
    border-radius: 3px;

    /*  */
    position: relative;
    cursor: pointer;
    transition: 0.1s;

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--color-light-white);
      border-radius: inherit;
      cursor: pointer;
      transition: 0.3s;
    }

    &::after {
      transform: translateY(7px);
    }

    &::before {
      transform: translateY(-7px);
    }
  }
}

#check {
  position: absolute;
  width: 4.5rem;
  height: 4.5rem;
  z-index: 50;
  cursor: pointer;
  opacity: 0;
  top: 7%;
  right: 0;
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div {
  background-color: transparent;
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div::before {
  transform: translateY(0) rotate(45deg);
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div::after {
  transform: translateY(0) rotate(-45deg);
}

/* header */
nav {
  display: flex;
}

.nav-nav {
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;

  /* z-index: 7; */
}

.nav__list {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;

  &-link {
    --pad: 1.6rem;
    color: var(--color-primary);
    font-weight: 500;
    padding: 0.8rem var(--pad);
    /*  */
    transition: all 0.1s;

    position: relative;

    &-active {
      --pad: 1.6rem;

      &::after {
        content: "";
        position: absolute;
        background-color: var(--color-primary);
        width: calc(100% - 2 * var(--pad));
        height: 2px;
        left: 50%;
        top: 88%;
        transform: translateX(-50%);
      }
    }
    // hover

    &:hover,
    &:active {
      transform: scale(1.1);
      color: $color-primary;
    }
  }
}

/* action */
.action {
  flex-basis: 25%;
  display: flex;
  align-items: center;
  gap: 2.4rem;
  justify-content: flex-end;
  text-align: center;
}

.action > * {
  flex: 1;
}

/* overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;

  z-index: 30;
}

/*  */
.main__nav-container {
  flex: 5;

  display: flex;
  // justify-content: space-around;
  // justify-content: flex-end;
  justify-content: flex-start;
  align-items: center;
}

.sticky-nav .hamburger-menu-container,
.sticky-nav #check {
  top: 33% !important;
}

//
.mobile__dot-navigation {
  display: none;
}

// mobile dot
.dot__navigation-icon {
  position: relative;

  .extra__navigation {
    position: absolute;
    z-index: 2;
    top: 120%;
    right: 10%;
    width: 20rem;
    // height: 100%;
    padding-top: 2rem;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    // padding-top: 1.6rem;
    // padding-bottom: 1.6rem;

    & a {
      color: #000;
      padding: 0.8rem 1.6rem;
      font-size: 1.4rem;
      font-weight: 500%;
      font-style: normal;
      width: 100%;
      // background-color: #fff;
      transition: all 0.3s;
      margin-bottom: 0;

      &:hover,
      &:active {
        color: $color-light-white;
        background-color: $color-primary;
        transform: scale(1);
      }
    }
  }
}

@media (max-width: 1495px) {
  .nav__list {
    // font-size: 1.4rem;
    gap: 0.4rem;
    .btn-logout {
      padding: 0;

      @media (max-width: 1190px) {
        position: absolute;
        bottom: 29px;
        right: 2rem;
      }
    }
  }

  @media (max-width: 1297px) {
    .nav__list-link {
      // font-size: 1.3rem;
    }
  }
}

@media (max-width: 1190px) {
  .hamburger-menu-container {
    display: flex;
    z-index: 45;
    // top: 7%;
    top: 1%;
    right: 3%;
  }

  #check {
    right: 1%;
    top: 1%;
  }

  .hamburger-menu {
    background-color: transparent;
  }

  .hamburger-menu div {
    background: $color-primary;
  }

  .hamburger-menu div::before {
    background-color: $color-primary;
  }

  .hamburger-menu div::after {
    background-color: $color-primary;
  }

  .nav-nav {
    z-index: 40;

    /* position: relative; */
  }

  .nav__list,
  .nav__list-container {
    /* display: flex; */
    flex-direction: column;

    width: 30rem;
    position: absolute;

    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
    overflow: hidden;
    pointer-events: none;
    top: 0;
    height: 100vh;
    right: 0;
    z-index: 5;
  }

  .sticky-nav .nav__list-container {
    /* top: 78%; */
    top: 99.5%;
  }

  .nav__list {
    top: 0;
    align-items: flex-end;
    background-color: #f5f4f9;
    opacity: 0;
    visibility: hidden;
    /* display: none; */
    transform: translateX(100%);
    transition: 0.3s all;
    padding: 3rem 0;
    padding-top: 10em;
  }

  .nav__list-link {
    width: 90%;
    text-align: start;
    /* padding: 0rem !important; */
    padding: 0.6rem 0.8rem !important;

    margin-bottom: 1.6rem;
    font-size: 2rem;
    font-weight: bold;
  }

  .nav__list-link-active {
    --pad: 3.2rem;
    border-left: 5px solid $color-primary;
    margin-left: 1.6rem;
  }

  .nav__list-link-active::after {
    display: none;
  }

  .nav__list a {
    font-size: 1.8rem;
    padding: 0.8rem 3.2rem;
  }

  #check:checked ~ .main__nav-container .nav__list {
    /* z-index: 20; */
    visibility: visible;
    opacity: 1;
    /* background-color: var(--color-light-white); */
    right: 0;
    transform: translateX(0%);
    pointer-events: auto;
  }

  #check:checked ~ .main__nav-container .nav__list-container {
    z-index: 30;
    pointer-events: auto;
  }

  #check:checked ~ .overlay {
    display: block;
  }

  #check:checked ~ .nav-nav {
    position: absolute;
    width: 100%;
    background-color: $color-light-white;
  }

  #check:checked,
  #check:checked ~ .hamburger-menu-container {
    top: 2rem !important;
    right: 4%;
  }

  .overflow {
    overflow: hidden;
  }

  /* mobile actions */
  .nav__list .mobile__actions {
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .nav__list .mobile__actions .btn-logIn {
    display: none;
  }

  .mobile__dot-navigation {
    display: block;
  }

  .mobile__dot-navigation {
    display: inline-block;
    pointer-events: auto !important;
  }

  .dot__navigation {
    position: absolute;
    top: 2.5%;
    right: 30%;

    @media (max-width: 800px) {
      top: 3%;
    }
  }

  .desktop__dot-navigation {
    display: none;
  }

  .mobile__dot-navigation {
    display: block;
  }
}

@media (max-width: 800px) {
  .nav__list,
  .nav__list-container {
    bottom: -44px;
  }

  .hamburger-menu-container {
    top: 0.5%;
  }

  #check {
    top: 0.5%;
  }

  #check:checked,
  #check:checked ~ .hamburger-menu-container {
    top: 3rem !important;
  }
}

@media (max-width: 500px) {
  html {
    font-size: 55%;
  }

  .logo svg {
    width: 13rem;
  }

  .nav__list,
  .nav__list-container {
    bottom: -40px;
  }
}

@media (max-width: 400px) {
  html {
    font-size: 50%;
  }

  .nav__list a {
    font-size: 2rem;
  }

  .nav__list,
  .nav__list-container {
    bottom: -37px;
  }
}

.view {
  font-size: 1.4rem;
  color: $color-primary;
  display: inline-block;
  position: relative;
  transition: all 0.3s;
  // margin-left: 93%;
  margin-bottom: 1.6rem;
  position: relative;
  left: 100%;
  transform: translateX(-100%);

  &:hover {
    color: $color-primary;
  }

  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 10%;
    bottom: 0;
    left: 0;
    background: $color-primary;
    transition: all 0.3s;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
}

.no__content {
  text-align: center;
  font-weight: bold;
  width: 90%;
}
